<template>
  <vue-scroll class="register-page">
    <div class="form-box card-base card-shadow--large">
          <div class="center">
             <h2 class="accent-text">Change Password</h2>
          </div>
          <el-divider class="mv-10 o-020"></el-divider>
          <div class="">
            <el-form :label-position="labelPasition" :rules="rules" ref="form" label-width="160px" :model="form">
                <el-form-item label="PASSWORD" prop="password">
                    <el-input size="medium" v-model="form.password" type="password" show-password></el-input>
                </el-form-item>
                <el-form-item label="RETYPE PASSWORD" prop="retypePwd">
                    <el-input size="medium" v-model="form.retypePwd" type="password" show-password></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="medium" @click="submit('form')" class="signin-btn mt-16">
                    SUBMIT
                  </el-button>
                </el-form-item>
            </el-form>
          </div>
    </div>
  </vue-scroll>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Password',

  data () {
    return {
      rules: {
        password: [
          { required: true, message: 'You cannot use a blank password.' },
          { type: 'string', min: 6, message: 'Too short' }
        ],
        retypePwd: [
          { required: true, message: 'Please retype the new password' },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('Please retype the new password'))
              } else {
                if (value !== this.form.password) {
                  callback(new Error('Passwords do not match'))
                }
                callback()
              }
            }
          }
        ]
      },
      form: {
        password: '',
        retypePwd: ''
      },
      innerWidth: 0
    }
  },

  computed: {
    labelPasition () {
      return this.innerWidth >= 768 ? 'left' : 'top'
    }
  },

  methods: {
    ...mapActions(['changePassword']),
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changePassword(this.form)
            .then(() => {
              this.form = {
                password: '',
                retypePwd: ''
              }
            })
            .then(() => {
              this.$message({
                showClose: true,
                message: 'Password has succefully updated',
                type: 'success'
              })
            })
            .then(() => {
              this.$onCommandParams('home')
            })
            .catch(err => {
              this.$message({
                showClose: true,
                message: err.message,
                type: 'error'
              })
            })
        }
      })
    },
    resizeOpenNav () {
      this.innerWidth = window.innerWidth
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  },
  mounted () {
    this.resizeOpenNav()
    window.addEventListener('resize', this.resizeOpenNav)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeOpenNav)
  }
}
</script>
